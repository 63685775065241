class Nav
	constructor: () ->
		@nav   = document.getElementById("nav")
		@title = @nav.childNodes[0].childNodes[0]

		@desktopLinks = @nav.childNodes[1].childNodes
		@mobileLinks  = @nav.childNodes[2]

		new HoverEffect(@title)
		for link in @desktopLinks
			new HoverEffect(link)

		@mobileLinks.addEventListener("change", ->
			document.location.href = @value
		,false)

new Nav